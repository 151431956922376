import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function TopUserTable() {
  const [aboutUs, setAboutUs] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [editAboutUs, setEditAboutUs] = React.useState({
    aboutUs: { en: '', es: '', fr: '', it: '', pg: '', ch: '', ru: '' },
    id: '67250ded0f689ab7f255f5f8',
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const fetchAboutUs = async () => {
    try {
      const response = await axios.post(`${baseURL}getAboutUs`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setAboutUs(response.data.aboutUs);
    } catch (error) {
      console.error('Error fetching translations:', error);
    }
  };
  React.useEffect(() => {
    fetchAboutUs();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditAboutUs((prevState) => ({
      ...prevState,
      aboutUs: {
        ...prevState.aboutUs,
        [name]: value,
      },
    }));
  };

  const handleEditAboutUs = async () => {
    try {
      await axios.post(`${baseURL}editAboutUs`, editAboutUs);
      await fetchAboutUs();
      onEditClose();
      setEditAboutUs({
        aboutUs: { en: '', es: '', fr: '', it: '', pg: '', ch: '', ru: '' },
        id: '67250ded0f689ab7f255f5f8',
      });
    } catch (error) {
      console.error('Error editing translation:', error);
    }
  };

  const openEditModal = (aboutUs) => {
    setEditAboutUs({
      aboutUs: {
        en: aboutUs.en,
        es: aboutUs.es,
        fr: aboutUs.fr,
        it: aboutUs.it,
        pg: aboutUs.pg,
        ch: aboutUs.ch,
        ru: aboutUs.ru,
      },
      id: '67250ded0f689ab7f255f5f8',
    });
    onEditOpen();
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          About Us
        </Text>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>English</Th>
              <Th>Spanish</Th>
              <Th>French</Th>
              <Th>Italian</Th>
              <Th>Portuguese</Th>
              <Th>Chinese</Th>
              <Th>Russian</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{aboutUs.en}</Td>
              <Td>{aboutUs.es}</Td>
              <Td>{aboutUs.fr}</Td>
              <Td>{aboutUs.it}</Td>
              <Td>{aboutUs.pg}</Td>
              <Td>{aboutUs.ch}</Td>
              <Td>{aboutUs.ru}</Td>
              <Td>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => openEditModal(aboutUs)}
                >
                  Edit
                </Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Edit Translation Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit About Us</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editAboutUs && (
              <>
                <FormControl>
                  <FormLabel>English</FormLabel>
                  <Textarea
                    name="en"
                    value={editAboutUs.aboutUs.en}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Spanish</FormLabel>
                  <Textarea
                    name="es"
                    value={editAboutUs.aboutUs.es}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>French</FormLabel>
                  <Textarea
                    name="fr"
                    value={editAboutUs.aboutUs.fr}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Italian</FormLabel>
                  <Textarea
                    name="it"
                    value={editAboutUs.aboutUs.it}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Portuguese</FormLabel>
                  <Textarea
                    name="pg"
                    value={editAboutUs.aboutUs.pg}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Chinese</FormLabel>
                  <Textarea
                    name="ch"
                    value={editAboutUs.aboutUs.ch}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Russian</FormLabel>
                  <Textarea
                    name="ru"
                    value={editAboutUs.aboutUs.ru}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleEditAboutUs}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
