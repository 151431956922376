import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineDynamicFeed,
  MdLanguage,
  MdMoney,
  MdSupport,
  MdError,
  MdPrivacyTip,
  MdAccessTime,
  MdInfo,
  MdEmail,
  MdCategory,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import SubAdmins from 'views/admin/subadmin';
import AppTranslation from 'views/admin/appTranslation';
import Tickets from 'views/admin/tickets';
import ErrorMessages from 'views/admin/errorMessages';
import PricingManagement from 'views/admin/PricingManagement';
import AboutUs from 'views/admin/AboutUs';
import PrivacyPolicy from 'views/admin/privacyPolicy';
import TermsOfUse from 'views/admin/termsOfUse';
import Categories from 'views/admin/categories';
import EmailTemplates from 'views/admin/emailTemplates';
import HelpCategories from 'views/admin/helpcategories';

const routes = [
  {
    name: 'Home',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },

  {
    name: 'Users',
    layout: '/admin',
    path: '/users',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <NFTMarketplace />,
  },
  {
    name: 'Sub Admins',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/subAdmin',
    component: <SubAdmins />,
  },
  {
    name: 'App Text',
    layout: '/admin',
    icon: (
      <Icon
        as={MdOutlineDynamicFeed}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: '/appText',
    component: <AppTranslation />,
  },
  {
    name: 'Tickets',
    layout: '/admin',
    icon: <Icon as={MdSupport} width="20px" height="20px" color="inherit" />,
    path: '/tickets',
    component: <Tickets />,
  },

  {
    name: 'Error Messages',
    layout: '/admin',
    icon: <Icon as={MdError} width="20px" height="20px" color="inherit" />,
    path: '/error',
    component: <ErrorMessages />,
  },

  {
    name: 'Categories',
    layout: '/admin',
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    path: '/categories',
    component: <Categories />,
  },

  {
    name: 'Help Categories',
    layout: '/admin',
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    path: '/helpcategories',
    component: <HelpCategories />,
  },

  {
    name: 'About Us',
    layout: '/admin',
    icon: <Icon as={MdInfo} width="20px" height="20px" color="inherit" />,
    path: '/about',
    component: <AboutUs />,
  },

  {
    name: 'Privacy Policy',
    layout: '/admin',
    icon: <Icon as={MdPrivacyTip} width="20px" height="20px" color="inherit" />,
    path: '/privacy',
    component: <PrivacyPolicy />,
  },

  {
    name: 'Terms of Use',
    layout: '/admin',
    icon: <Icon as={MdAccessTime} width="20px" height="20px" color="inherit" />,
    path: '/terms',
    component: <TermsOfUse />,
  },

  {
    name: 'Email Templates',
    layout: '/admin',
    icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
    path: '/email',
    component: <EmailTemplates />,
  },

  {
    name: 'Pricing Management',
    layout: '/admin',
    icon: <Icon as={MdMoney} width="20px" height="20px" color="inherit" />,
    path: '/pricing',
    component: <PricingManagement />,
  },
  {
    name: 'Logout',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },

  // {
  //   name: 'Language',
  //   layout: '/admin',
  //   icon: <Icon as={MdLanguage} width="20px" height="20px" color="inherit" />,
  //   path: '/data-tables',
  //   component: <DataTables />,
  // },
  // {
  //   name: 'Pricing Management',
  //   layout: '/admin',
  //   icon: <Icon as={MdMoney} width="20px" height="20px" color="inherit" />,
  //   path: '/data-tables',
  //   component: <DataTables />,
  //},
  // {
  //   name: 'Profile',
  //   layout: '/admin',
  //   path: '/profile',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <Profile />,
  // },

  // {
  //   name: 'RTL Admin',
  //   layout: '/rtl',
  //   path: '/rtl-default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: <RTL />,
  // },
];

export default routes;
