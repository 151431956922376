/* eslint-disable */

import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import axios from 'axios'; // Ensure you have axios installed
import { baseURL } from 'constants/constant';
import { useData } from 'contexts/dataContext';

const columnHelper = createColumnHelper();

export default function TopUserTable() {
  const [tableData, setTableData] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control
  const [userIdToDelete, setUserIdToDelete] = React.useState(null);
  const { admin } = useData();
  console.log('ADMIN ====', admin);
  const columns = [
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          FIRST NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          LAST NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('emailAddress', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          EMAIL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('collaboratingWith', {
      id: 'collaboratingWith',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          COLLABORATING WITH
        </Text>
      ),
      cell: (info) => (
        <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('balance', {
      id: 'balance',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          BALANCE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('editLog', {
      id: 'editLog',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          EDIT LOG
        </Text>
      ),
      cell: (info) => (
        <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
          {info.getValue().length}
        </Text>
      ),
    }),
    columnHelper.accessor('uncollaborationLog', {
      id: 'Uncollaboration Log',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          UNCOLLABORATION LOG
        </Text>
      ),
      cell: (info) => {
        const searchLog = info.getValue(); // This should be an array
        const logLength = Array.isArray(searchLog) ? searchLog.length : 0; // Get length safely

        return (
          <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
            {logLength} {logLength === 1 ? '' : ''}{' '}
            {/* Optional: Pluralization */}
          </Text>
        );
      },
    }),
    columnHelper.accessor('deleteLog', {
      id: 'deleteLog',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          DELETE LOG
        </Text>
      ),
      cell: (info) => (
        <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
          {info.getValue().length}
        </Text>
      ),
    }),
    columnHelper.accessor('searchLog', {
      id: 'Search Log',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          SEARCH LOG
        </Text>
      ),
      cell: (info) => {
        const searchLog = info.getValue(); // This should be an array
        const logLength = Array.isArray(searchLog) ? searchLog.length : 0; // Get length safely

        return (
          <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
            {logLength} {logLength === 1 ? '' : ''}{' '}
            {/* Optional: Pluralization */}
          </Text>
        );
      },
    }),

    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          ACTIONS
        </Text>
      ),
      cell: (info) => {
        const userId = info.row.original._id; // Assuming each user has a unique 'id'
        return (
          <Flex>
            {/* <Button
              size="sm"
              colorScheme="blue"
              onClick={() => handleEdit(userId)}
              mr="2"
            >
              Details
            </Button> */}
            <Button
              size="sm"
              colorScheme="red"
              onClick={() => {
                setUserIdToDelete(userId); // Set user ID to delete
                onOpen(); // Open the confirmation modal
              }}
            >
              Delete
            </Button>
          </Flex>
        );
      },
    }),
  ];

  // Fetch users from the API
  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.post(`${baseURL}getUsers`);
        const users = response.data.users;
        setTableData(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = (id) => {
    console.log(`Edit user with id: ${id}`);
    // Implement your edit functionality here
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(`${baseURL}deleteUser`, {
        id,
      });
      const response = await axios.post(`${baseURL}getUsers`);
      const users = response.data.users;
      setTableData(users);
      onClose(); // Close the modal after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        align={{ sm: 'flex-start', lg: 'center' }}
        justify="space-between"
        w="100%"
        px="22px"
        pb="20px"
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
      >
        <Text color={textColor} fontSize="xl" fontWeight="600">
          All Users
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <Box pl="10px">
                          {header.column.getIsSorted() === 'asc' ? (
                            <i className="fas fa-sort-up" />
                          ) : header.column.getIsSorted() === 'desc' ? (
                            <i className="fas fa-sort-down" />
                          ) : null}
                        </Box>
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id} borderColor={borderColor}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} borderColor={borderColor}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this user?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => handleDelete(userIdToDelete)}
            >
              Delete
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
