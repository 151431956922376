import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function TopUserTable() {
  const [privacyPolicy, setPrivacyPolicy] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [editPrivacyPolicy, setEditPrivacyPolicy] = React.useState({
    privacyPolicy: { en: '', es: '', fr: '', it: '', pg: '', ch: '', ru: '' },
    id: '67250ded0f689ab7f255f5f8',
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await axios.post(`${baseURL}getPrivacyPolicy`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setPrivacyPolicy(response.data.privacyPolicy);
    } catch (error) {
      console.error('Error fetching translations:', error);
    }
  };
  React.useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditPrivacyPolicy((prevState) => ({
      ...prevState,
      privacyPolicy: {
        ...prevState.privacyPolicy,
        [name]: value,
      },
    }));
  };

  const handleEditAboutUs = async () => {
    try {
      await axios.post(`${baseURL}editPrivacyPolicy`, editPrivacyPolicy);
      await fetchPrivacyPolicy();
      onEditClose();
      setEditPrivacyPolicy({
        privacyPolicy: {
          en: '',
          es: '',
          fr: '',
          it: '',
          pg: '',
          ch: '',
          ru: '',
        },
        id: '67250ded0f689ab7f255f5f8',
      });
    } catch (error) {
      console.error('Error editing translation:', error);
    }
  };

  const openEditModal = (aboutUs) => {
    setEditPrivacyPolicy({
      privacyPolicy: {
        en: privacyPolicy.en,
        es: privacyPolicy.es,
        fr: privacyPolicy.fr,
        it: privacyPolicy.it,
        pg: privacyPolicy.pg,
        ch: privacyPolicy.ch,
        ru: privacyPolicy.ru,
      },
      id: '67250ded0f689ab7f255f5f8',
    });
    onEditOpen();
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          Privacy Policy
        </Text>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>English</Th>
              <Th>Spanish</Th>
              <Th>French</Th>
              <Th>Italian</Th>
              <Th>Portuguese</Th>
              <Th>Chinese</Th>
              <Th>Russian</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{privacyPolicy.en}</Td>
              <Td>{privacyPolicy.es}</Td>
              <Td>{privacyPolicy.fr}</Td>
              <Td>{privacyPolicy.it}</Td>
              <Td>{privacyPolicy.pg}</Td>
              <Td>{privacyPolicy.ch}</Td>
              <Td>{privacyPolicy.ru}</Td>
              <Td>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => openEditModal(privacyPolicy)}
                >
                  Edit
                </Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Edit Translation Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editPrivacyPolicy && (
              <>
                <FormControl>
                  <FormLabel>English</FormLabel>
                  <Textarea
                    name="en"
                    value={editPrivacyPolicy.privacyPolicy.en}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Spanish</FormLabel>
                  <Textarea
                    name="es"
                    value={editPrivacyPolicy.privacyPolicy.es}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>French</FormLabel>
                  <Textarea
                    name="fr"
                    value={editPrivacyPolicy.privacyPolicy.fr}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Italian</FormLabel>
                  <Textarea
                    name="it"
                    value={editPrivacyPolicy.privacyPolicy.it}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Portuguese</FormLabel>
                  <Textarea
                    name="pg"
                    value={editPrivacyPolicy.privacyPolicy.pg}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Chinese</FormLabel>
                  <Textarea
                    name="ch"
                    value={editPrivacyPolicy.privacyPolicy.ch}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Russian</FormLabel>
                  <Textarea
                    name="ru"
                    value={editPrivacyPolicy.privacyPolicy.ru}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleEditAboutUs}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
