import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';
import Select from 'react-select';
import { countryData } from './countries'; // Assuming this contains the country data

export default function BillingGroupTable() {
  const [billingGroups, setBillingGroups] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [newBillingGroup, setNewBillingGroup] = React.useState({
    name: '',
    continent: '',
    searchPrice: '',
    collaborationPrice: '',
    countries: [],
    id: '67250ded0f689ab7f255f5f8',
  });

  const [editBillingGroup, setEditBillingGroup] = React.useState({
    name: '',
    continent: '',
    searchPrice: '',
    collaborationPrice: '',
    countries: [],
    id: '67250ded0f689ab7f255f5f8',
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const continentOptions = Object.keys(countryData).map((continent) => ({
    label: continent,
    value: continent,
  }));

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  React.useEffect(() => {
    const fetchBillingGroups = async () => {
      try {
        const response = await axios.post(`${baseURL}getBillingGroups`, {
          id: '67250ded0f689ab7f255f5f8',
        });
        setBillingGroups(response.data.billingGroups);
      } catch (error) {
        console.error('Error fetching billing groups:', error);
      }
    };
    fetchBillingGroups();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (isEditOpen) {
      setEditBillingGroup((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewBillingGroup((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddBillingGroup = async () => {
    try {
      await axios.post(`${baseURL}createBillingGroup`, newBillingGroup);
      const response = await axios.post(`${baseURL}getBillingGroups`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setBillingGroups(response.data.billingGroups);
      onAddClose();
      setNewBillingGroup({
        name: '',
        continent: '',
        searchPrice: '',
        collaborationPrice: '',
        countries: [],
        id: '67250ded0f689ab7f255f5f8',
      });
    } catch (error) {
      console.error('Error adding billing group:', error);
    }
  };

  const handleEditBillingGroup = async () => {
    try {
      await axios.post(`${baseURL}editBillingGroup`, editBillingGroup);
      const response = await axios.post(`${baseURL}getBillingGroups`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setBillingGroups(response.data.billingGroups);
      onEditClose();
      setEditBillingGroup({
        name: '',
        continent: '',
        searchPrice: '',
        collaborationPrice: '',
        countries: [],
        id: '67250ded0f689ab7f255f5f8',
      });
    } catch (error) {
      console.error('Error editing billing group:', error);
    }
  };

  const openEditModal = (billingGroup) => {
    setEditBillingGroup({
      name: billingGroup.name,
      continent: billingGroup.continent,
      searchPrice: billingGroup.searchPrice,
      collaborationPrice: billingGroup.collaborationPrice,
      countries: billingGroup.countries,
      id: '67250ded0f689ab7f255f5f8',
    });
    onEditOpen();
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          Billing Groups
        </Text>
        <Button colorScheme="blue" onClick={onAddOpen}>
          Add Billing Group
        </Button>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Continent</Th>
              <Th>Search Price</Th>
              <Th>Collaboration Price</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {billingGroups.map((billingGroup) => (
              <Tr key={billingGroup._id}>
                <Td>{billingGroup.name}</Td>
                <Td>{billingGroup.continent}</Td>
                <Td>{billingGroup.searchPrice}</Td>
                <Td>{billingGroup.collaborationPrice}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => openEditModal(billingGroup)}
                  >
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Add Billing Group Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Billing Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={newBillingGroup.name}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel>Continent</FormLabel>
              <Select
                name="continent"
                options={continentOptions}
                value={continentOptions.find(
                  (option) => option.value === newBillingGroup.continent,
                )}
                onChange={(selectedOption) => {
                  // Get the continent selected
                  const selectedContinent = selectedOption.value;

                  // Get countries for the selected continent from the countryData
                  const countriesForContinent =
                    countryData[selectedContinent] || [];

                  setNewBillingGroup((prev) => ({
                    ...prev,
                    continent: selectedContinent, // Update the continent
                    countries: countriesForContinent, // Update countries based on selected continent
                  }));
                }}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel>Search Price</FormLabel>
              <Input
                name="searchPrice"
                value={newBillingGroup.searchPrice}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel>Collaboration Price</FormLabel>
              <Input
                name="collaborationPrice"
                value={newBillingGroup.collaborationPrice}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddBillingGroup}>
              Add
            </Button>
            <Button variant="ghost" onClick={onAddClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Billing Group Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Billing Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editBillingGroup && (
              <>
                <FormControl mb="4">
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="name"
                    value={editBillingGroup.name}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Continent</FormLabel>
                  <Select
                    name="continent"
                    options={continentOptions}
                    value={continentOptions.find(
                      (option) => option.value === editBillingGroup.continent,
                    )}
                    onChange={(selectedOption) => {
                      // Get the continent selected
                      const selectedContinent = selectedOption.value;

                      // Get countries for the selected continent from the countryData
                      const countriesForContinent =
                        countryData[selectedContinent] || [];

                      setEditBillingGroup((prev) => ({
                        ...prev,
                        continent: selectedContinent, // Update the continent
                        countries: countriesForContinent, // Update countries based on selected continent
                      }));
                    }}
                  />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Search Price</FormLabel>
                  <Input
                    name="searchPrice"
                    value={editBillingGroup.searchPrice}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Collaboration Price</FormLabel>
                  <Input
                    name="collaborationPrice"
                    value={editBillingGroup.collaborationPrice}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleEditBillingGroup}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
