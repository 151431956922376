// DataContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [admin, setAdmin] = useState(false);
  return (
    <DataContext.Provider value={{ admin, setAdmin }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
