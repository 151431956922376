import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import { DataProvider } from 'contexts/dataContext';
import { PaymentProvider } from 'contexts/paymentContext';
import { LoadingProvider } from 'contexts/loadingContext';

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <DataProvider>
      <LoadingProvider>
        <PaymentProvider>
          <ChakraProvider theme={currentTheme}>
            <Routes>
              <Route path="auth/*" element={<AuthLayout />} />
              <Route
                path="admin/*"
                element={
                  <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
                }
              />
              <Route
                path="rtl/*"
                element={
                  <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
                }
              />
              <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
            </Routes>
          </ChakraProvider>
        </PaymentProvider>
      </LoadingProvider>
    </DataProvider>
  );
}
