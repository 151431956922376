import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function Categories() {
  const [categories, setCategories] = React.useState([]);
  const [showSubCategories, setShowSubCategories] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const [showFAQs, setShowFAQs] = React.useState(false);
  const [selectedFAQ, setSelectedFAQ] = React.useState(null);
  const [editItem, setEditItem] = React.useState({
    en: '',
    es: '',
    fr: '',
    it: '',
    pg: '',
    ch: '',
    ru: '',
    categoryId: '',
  });

  const [editSubCategory, setEditSubCategory] = React.useState({
    en: '',
    es: '',
    fr: '',
    it: '',
    pg: '',
    ch: '',
    ru: '',
    subcategoryId: '',
    categoryId: '',
  });

  const [editFAQ, setEditFAQ] = React.useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSubCategoryOpen,
    onOpen: onSubCategoryOpen,
    onClose: onSubCategoryClose,
  } = useDisclosure();

  const {
    isOpen: isFAQOpen,
    onOpen: onFAQOpen,
    onClose: onFAQClose,
  } = useDisclosure();

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const fetchCategories = async () => {
    try {
      const response = await axios.post(`${baseURL}getCategories`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setCategories(response.data.categories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubCategoryInputChange = (e) => {
    const { name, value } = e.target;
    setEditSubCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveItem = async () => {
    try {
      let endpoint = '';
      const payload = {
        id: '67250ded0f689ab7f255f5f8',
        ...editItem,
      };
      if (editItem.categoryId) {
        endpoint = 'editCategory';
      } else {
        endpoint = 'addCategory';
      }
      await axios.post(`${baseURL}${endpoint}`, payload);
      fetchCategories();
      onClose();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleSaveSubCategory = async () => {
    try {
      let endpoint = '';
      const payload = {
        id: '67250ded0f689ab7f255f5f8',
        ...editSubCategory,
      };
      if (editSubCategory.subcategoryId) {
        endpoint = 'editSubCategory';
      } else {
        endpoint = 'addSubCategory';
      }
      await axios.post(`${baseURL}${endpoint}`, payload);
      fetchCategories();
      onSubCategoryClose();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const openEditModal = (item) => {
    setEditItem({
      en: item.name.en || '',
      es: item.name.es || '',
      fr: item.name.fr || '',
      it: item.name.it || '',
      pg: item.name.pg || '',
      ch: item.name.ch || '',
      ru: item.name.ru || '',
      categoryId: item._id,
    });
    onOpen();
  };

  const openEditSubCategoryModal = (item) => {
    setEditSubCategory({
      en: item.name.en || '',
      es: item.name.es || '',
      fr: item.name.fr || '',
      it: item.name.it || '',
      pg: item.name.pg || '',
      ch: item.name.ch || '',
      ru: item.name.ru || '',
      subcategoryId: item._id || '',
      categoryId: selectedCategory._id,
    });
    onSubCategoryOpen();
  };

  const handleAddItem = () => {
    setEditItem({
      en: '',
      es: '',
      fr: '',
      it: '',
      pg: '',
      ch: '',
      ru: '',
      categoryId: '',
    });
    onOpen();
  };

  const handleAddSubCategory = () => {
    setEditSubCategory({
      en: '',
      es: '',
      fr: '',
      it: '',
      pg: '',
      ch: '',
      ru: '',
      subcategoryId: '',
      categoryId: selectedCategory._id,
    });
    onSubCategoryOpen();
  };

  const handleFAQInputChange = (e) => {
    const { name, value } = e.target;
    setEditFAQ((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveFAQ = async (modalType) => {
    try {
      const endpoint = modalType === 'add' ? 'addFAQ' : 'editFAQ';
      const payload = {
        id: '67250ded0f689ab7f255f5f8',
        categoryId: selectedCategory._id,
        subCategoryId: selectedSubCategory._id,
        ...editFAQ,
      };

      await axios.post(`${baseURL}${endpoint}`, payload);
      fetchCategories();
      onFAQClose();
    } catch (error) {
      console.error('Error saving FAQ:', error);
    }
  };

  const openFAQModal = (faq = {}) => {
    setEditFAQ({
      questionEn: faq?.question?.en || '',
      questionEs: faq?.question?.es || '',
      questionFr: faq?.question?.fr || '',
      questionIt: faq?.question?.it || '',
      questionPg: faq?.question?.pg || '',
      questionCh: faq?.question?.ch || '',
      questionRu: faq?.question?.ru || '',
      answerEn: faq?.answer?.en || '',
      answerEs: faq?.answer?.es || '',
      answerFr: faq?.answer?.fr || '',
      answerIt: faq?.answer?.it || '',
      answerPg: faq?.answer?.pg || '',
      answerCh: faq?.answer?.ch || '',
      answerRu: faq?.answer?.ru || '',
      faqId: faq._id || null,
    });
    onFAQOpen();
  };
  return (
    <Flex direction="column" w="100%" overflowX="auto">
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        {!showSubCategories && !showFAQs ? (
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Categories
          </Text>
        ) : (
          <Button
            onClick={() => {
              if (showFAQs) {
                setShowFAQs(false);
                setShowSubCategories(true);
              } else {
                setShowSubCategories(false);
              }
            }}
          >
            Back
          </Button>
        )}
        <Button
          onClick={() => {
            if (showSubCategories) {
              handleAddSubCategory();
            } else if (showFAQs) {
              openFAQModal();
            } else {
              handleAddItem();
            }
          }}
        >
          Add{' '}
          {showSubCategories ? 'Sub Category' : showFAQs ? 'FAQ' : 'Category'}
        </Button>
      </Flex>

      {!showSubCategories && !showFAQs && (
        <Box>
          <Table variant="simple" color="gray.500" mt="12px">
            <Thead>
              <Tr>
                <Th>English</Th>
                <Th>Spanish</Th>
                <Th>French</Th>
                <Th>Italian</Th>
                <Th>Portuguese</Th>
                <Th>Chinese</Th>
                <Th>Russian</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {categories.map((item) => (
                <Tr
                  key={item._id}
                  onClick={() => {
                    setSelectedCategory(item);
                    setShowSubCategories(true);
                    setShowFAQs(false);
                  }}
                  cursor={'pointer'}
                >
                  <Td>{item.name.en}</Td>
                  <Td>{item.name.es}</Td>
                  <Td>{item.name.fr}</Td>
                  <Td>{item.name.it}</Td>
                  <Td>{item.name.pg}</Td>
                  <Td>{item.name.ch}</Td>
                  <Td>{item.name.ru}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => openEditModal(item)}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {showSubCategories && !showFAQs && (
        <Box>
          <Table variant="simple" color="gray.500" mt="12px">
            <Thead>
              <Tr>
                <Th>English</Th>
                <Th>Spanish</Th>
                <Th>French</Th>
                <Th>Italian</Th>
                <Th>Portuguese</Th>
                <Th>Chinese</Th>
                <Th>Russian</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectedCategory &&
                selectedCategory.subcategories.map((item) => (
                  <Tr
                    key={item._id}
                    onClick={() => {
                      setSelectedSubCategory(item);
                      setShowSubCategories(false);
                      setShowFAQs(true);
                    }}
                    cursor={'pointer'}
                  >
                    <Td>{item.name.en}</Td>
                    <Td>{item.name.es}</Td>
                    <Td>{item.name.fr}</Td>
                    <Td>{item.name.it}</Td>
                    <Td>{item.name.pg}</Td>
                    <Td>{item.name.ch}</Td>
                    <Td>{item.name.ru}</Td>
                    <Td>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={() => openEditSubCategoryModal(item)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {showFAQs && !showSubCategories && (
        <Box>
          <Table variant="simple" color="gray.500" mt="12px">
            <Thead>
              <Tr>
                <Th>FAQ</Th>
                {selectedSubCategory &&
                  selectedSubCategory.faqs.length > 0 &&
                  Object.keys(selectedSubCategory.faqs[0].question).map(
                    (lang) => (
                      <Th key={`lang-header-${lang}`}>{lang.toUpperCase()}</Th>
                    ),
                  )}
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectedSubCategory &&
                selectedSubCategory.faqs.map((faq) => (
                  <>
                    {/* Row for Questions */}
                    <Tr key={`question-${faq._id}`}>
                      <Td>Question</Td>
                      {Object.keys(faq.question).map((lang) => (
                        <Td key={`question-${faq._id}-${lang}`}>
                          {faq.question[lang]}
                        </Td>
                      ))}
                      <Td rowSpan={2}>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => openFAQModal(faq)}
                        >
                          Edit
                        </Button>
                      </Td>
                    </Tr>

                    {/* Row for Answers */}
                    <Tr key={`answer-${faq._id}`}>
                      <Td>Answer</Td>
                      {Object.keys(faq.answer).map((lang) => (
                        <Td key={`answer-${faq._id}-${lang}`}>
                          {faq.answer[lang]}
                        </Td>
                      ))}
                    </Tr>
                  </>
                ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* FAQ Modal */}
      <Modal isOpen={isFAQOpen} onClose={onFAQClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editFAQ.faqId ? 'Edit FAQ' : 'Add FAQ'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Questions */}
            <FormControl mb="4">
              <FormLabel>Question (EN)</FormLabel>
              <Input
                type="text"
                name="questionEn"
                value={editFAQ.questionEn}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Question (ES)</FormLabel>
              <Input
                type="text"
                name="questionEs"
                value={editFAQ.questionEs}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Question (FR)</FormLabel>
              <Input
                type="text"
                name="questionFr"
                value={editFAQ.questionFr}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Question (IT)</FormLabel>
              <Input
                type="text"
                name="questionIt"
                value={editFAQ.questionIt}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Question (PG)</FormLabel>
              <Input
                type="text"
                name="questionPg"
                value={editFAQ.questionPg}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Question (CH)</FormLabel>
              <Input
                type="text"
                name="questionCh"
                value={editFAQ.questionCh}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Question (RU)</FormLabel>
              <Input
                type="text"
                name="questionRu"
                value={editFAQ.questionRu}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>

            {/* Answers */}
            <FormControl mb="4">
              <FormLabel>Answer (EN)</FormLabel>
              <Input
                type="text"
                name="answerEn"
                value={editFAQ.answerEn}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Answer (ES)</FormLabel>
              <Input
                type="text"
                name="answerEs"
                value={editFAQ.answerEs}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Answer (FR)</FormLabel>
              <Input
                type="text"
                name="answerFr"
                value={editFAQ.answerFr}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Answer (IT)</FormLabel>
              <Input
                type="text"
                name="answerIt"
                value={editFAQ.answerIt}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Answer (PG)</FormLabel>
              <Input
                type="text"
                name="answerPg"
                value={editFAQ.answerPg}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Answer (CH)</FormLabel>
              <Input
                type="text"
                name="answerCh"
                value={editFAQ.answerCh}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Answer (RU)</FormLabel>
              <Input
                type="text"
                name="answerRu"
                value={editFAQ.answerRu}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => {
                handleSaveFAQ(editFAQ.faqId ? 'edit' : 'add');
              }}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onFAQClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Adding/Editing Category */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editItem.categoryId ? 'Edit Category' : 'Add Category'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(editItem).map(
              (key) =>
                !['categoryId'].includes(key) && (
                  <FormControl key={key} mb="4">
                    <FormLabel textTransform="capitalize">{key}</FormLabel>
                    <Input
                      type="text"
                      name={key}
                      value={editItem[key]}
                      onChange={handleInputChange}
                      color={textColor}
                    />
                  </FormControl>
                ),
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveItem}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSubCategoryOpen} onClose={onSubCategoryClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editSubCategory.subcategoryId
              ? 'Edit SubCategory'
              : 'Add SubCategory'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(editSubCategory).map(
              (key) =>
                !['subcategoryId'].includes(key) && (
                  <FormControl key={key} mb="4">
                    <FormLabel textTransform="capitalize">{key}</FormLabel>
                    <Input
                      type="text"
                      name={key}
                      value={editSubCategory[key]}
                      onChange={handleSubCategoryInputChange}
                      color={textColor}
                    />
                  </FormControl>
                ),
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveSubCategory}>
              Save
            </Button>
            <Button variant="ghost" onClick={onSubCategoryClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
