import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export default function LoadingBar() {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      height="6px"
      zIndex="9999"
      background="linear-gradient(to right, #4318FF, #868CFF, #4318FF)"
      backgroundSize="1000px 100%"
      animation={`${shimmer} 2s infinite linear`}
    />
  );
}
