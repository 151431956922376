import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function TopUserTable() {
  const [errorMessages, setErrorMessages] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  // Update to include id and translationId
  const [newErrorMessage, setNewErrorMessage] = React.useState({
    errorType: '',
    messageEn: '',
    messageEs: '',
    messageFr: '',
    messageIt: '',
    messagePg: '',
    messageCh: '',
    messageRu: '',
    id: '67250ded0f689ab7f255f5f8', // Admin ID for add
  });

  const [editErrorMessage, setEditErrorMessage] = React.useState({
    errorType: '',
    messageEn: '',
    messageEs: '',
    messageFr: '',
    messageIt: '',
    messagePg: '',
    messageCh: '',
    messageRu: '',
    id: '67250ded0f689ab7f255f5f8',
    errorMessageId: '',
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Fetch Translations
  React.useEffect(() => {
    const fetchErrorMessages = async () => {
      try {
        const response = await axios.post(`${baseURL}getErrorMessage`, {
          id: '67250ded0f689ab7f255f5f8',
        });
        setErrorMessages(response.data.errorMessages);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };
    fetchErrorMessages();
  }, []);

  // Handle Input Change
  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update state separately for new and editing messages
    if (isEditOpen) {
      setEditErrorMessage((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewErrorMessage((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Add Translation
  const handleAddErrorMessage = async () => {
    try {
      await axios.post(`${baseURL}addErrorMessage`, newErrorMessage);
      const response = await axios.post(`${baseURL}getErrorMessage`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setErrorMessages(response.data.errorMessages);
      onAddClose();
      // Reset new translation fields
      setNewErrorMessage({
        errorType: '',
        messageEn: '',
        messageEs: '',
        messageFr: '',
        messageIt: '',
        messagePg: '',
        messageCh: '',
        messageRu: '',
        id: '67250ded0f689ab7f255f5f8',
      });
    } catch (error) {
      console.error('Error adding translation:', error);
    }
  };

  // Edit Translation
  const handleEditErrorMessage = async () => {
    try {
      await axios.post(`${baseURL}editErrorMessage`, editErrorMessage);
      const response = await axios.post(`${baseURL}getErrorMessage`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setErrorMessages(response.data.errorMessages);
      onEditClose();
      // Reset editTranslation state after closing
      setEditErrorMessage({
        errorType: '',
        messageEn: '',
        messageEs: '',
        messageFr: '',
        messageIt: '',
        messagePg: '',
        messageCh: '',
        messageRu: '',
        id: '67250ded0f689ab7f255f5f8',
        errorMessageId: '',
      });
    } catch (error) {
      console.error('Error editing translation:', error);
    }
  };

  const openEditModal = (errorMessage) => {
    setEditErrorMessage({
      errorType: errorMessage.errorType,
      messageEn: errorMessage.messages.en,
      messageEs: errorMessage.messages.es,
      messageFr: errorMessage.messages.fr,
      messageIt: errorMessage.messages.it,
      messagePg: errorMessage.messages.pg,
      messageCh: errorMessage.messages.ch,
      messageRu: errorMessage.messages.ru,
      id: '67250ded0f689ab7f255f5f8',
      errorMessageId: errorMessage._id,
    });
    onEditOpen();
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          Error Messages
        </Text>
        <Button colorScheme="blue" onClick={onAddOpen}>
          Add Error Message
        </Button>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>Error Type</Th>
              <Th>English</Th>
              <Th>Spanish</Th>
              <Th>French</Th>
              <Th>Italian</Th>
              <Th>Portuguese</Th>
              <Th>Chinese</Th>
              <Th>Russian</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {errorMessages.map((errorMessage) => (
              <Tr key={errorMessage._id}>
                <Td>{errorMessage.errorType}</Td>
                <Td>{errorMessage.messages.en}</Td>
                <Td>{errorMessage.messages.es}</Td>
                <Td>{errorMessage.messages.fr}</Td>
                <Td>{errorMessage.messages.it}</Td>
                <Td>{errorMessage.messages.pg}</Td>
                <Td>{errorMessage.messages.ch}</Td>
                <Td>{errorMessage.messages.ru}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => openEditModal(errorMessage)}
                  >
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Add Translation Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Error Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Error Type</FormLabel>
              <Input
                name="errorType"
                value={newErrorMessage.errorType}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>English</FormLabel>
              <Input
                name="messageEn"
                value={newErrorMessage.messageEn}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Spanish</FormLabel>
              <Input
                name="messageEs"
                value={newErrorMessage.messageEs}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>French</FormLabel>
              <Input
                name="messageFr"
                value={newErrorMessage.messageFr}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Italian</FormLabel>
              <Input
                name="messageIt"
                value={newErrorMessage.messageIt}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Portuguese</FormLabel>
              <Input
                name="messagePg"
                value={newErrorMessage.messagePg}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Chinese</FormLabel>
              <Input
                name="messageCh"
                value={newErrorMessage.messageCh}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Russian</FormLabel>
              <Input
                name="messageRu"
                value={newErrorMessage.messageRu}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddErrorMessage}>
              Add
            </Button>
            <Button variant="ghost" onClick={onAddClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Translation Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Error Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editErrorMessage && (
              <>
                <FormControl mb="4">
                  <FormLabel>Error Type</FormLabel>
                  <Input
                    name="errorType"
                    value={editErrorMessage.errorType}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>English</FormLabel>
                  <Input
                    name="messageEn"
                    value={editErrorMessage.messageEn}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Spanish</FormLabel>
                  <Input
                    name="messageEs"
                    value={editErrorMessage.messageEs}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>French</FormLabel>
                  <Input
                    name="messageFr"
                    value={editErrorMessage.messageFr}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Italian</FormLabel>
                  <Input
                    name="messageIt"
                    value={editErrorMessage.messageIt}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Portuguese</FormLabel>
                  <Input
                    name="messagePg"
                    value={editErrorMessage.messagePg}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Chinese</FormLabel>
                  <Input
                    name="messageCh"
                    value={editErrorMessage.messageCh}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Russian</FormLabel>
                  <Input
                    name="messageRu"
                    value={editErrorMessage.messageRu}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleEditErrorMessage}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
