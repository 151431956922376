export const tableColumnsTopUsers = [
  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
  },
  {
    Header: 'Collaborating With',
    accessor: 'collaboratingWith',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Search Log',
    accessor: 'searchLog',
  },
  {
    Header: 'Edit Log',
    accessor: 'editLog',
  },
  {
    Header: 'Uncollaboration Log',
    accessor: 'unCollaborationLog',
  },
  {
    Header: 'Delete Log',
    accessor: 'deleteLog',
  },

  // {
  //   Header: 'Tickets',
  //   accessor: 'tickets',
  // },
  // {
  //   Header: 'Referrals',
  //   accessor: 'referrals',
  // },
];
