export const tableColumnsTopUsers = [
  {
    Header: 'ID',
    accessor: '_id',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Messages',
    accessor: 'messages',
  },
  {
    Header: 'Assigned',
    accessor: 'assigned',
  },
  {
    Header: 'Assigned To',
    accessor: 'assignedTo',
  },
];
